import { random } from './main';

export function anim11() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });

    var items = ['.anim11-btn'];
    tl.to(items, {
        duration: 0.5,
        scale:1.2,
        ease: Power4.easeIn    
    });
    tl.to(items, {
        duration: 0.5,
        scale:1,
        ease: Power4.easeOut
    });

    return tl;


}
