export function anim3() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    var tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });



    tl2.to(".anim3 path, .anim3 line", {
        duration: 2,
        strokeDashoffset: -100,
        ease: "none"
    });
    tl.to(".anim3 .anim3-btn", {
        duration: 2,
        scale: 0.8,
        transformOrigin: "50% 50%",
        ease: Elastic.easeInOut,
        stagger: .1,
    }, 0);
    tl.to(".anim3 .anim3-btn", {
        duration: 2,
        scale: 1,
        transformOrigin: "50% 50%",
        ease: Elastic.easeInOut,
        stagger: 1,
    }, 1);


    return [tl, tl2];

}
