export function anim2() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    function animRow(nr, offset) {

        var tl2 = gsap.timeline();

        tl2.to(`.anim2-row:nth-child(${nr}) .anim2-text1, .anim2-row:nth-child(${nr}) .anim2-text2`, {
            duration: 0.5,
            background: "#ADDBD2",
            ease: Sine.easeInOut,
            stagger: 0.2
        },offset);
        tl2.to(`.anim2-row:nth-child(${nr}) .anim2-icon-cross`, {
            duration: 0.5,
            opacity: 0,
            ease: Sine.easeInOut
        },offset);
        tl2.fromTo(`.anim2-row:nth-child(${nr}) .anim2-icon-check`, {
            scale: 0,
        },
        {
            duration: 1,
            opacity: 1,
            scale: 1,
            ease: Elastic.easeInOut
        },offset);
        return tl2;
    }
    function animSet(nr) {

        return gsap.set(`.anim2-row:nth-child(${nr}) .anim2-text1, .anim2-row:nth-child(${nr}) .anim2-text2`, {
            
            background: "#ADDBD2",
        });
        
    }

    tl.add(animSet(2));
    tl.add(animSet(5));
    tl.add(animSet(1));
    tl.set(".anim2-row", {
        // scale: 0.7,
        x: 30,
        opacity: 0,
    });
    tl.set(".anim2-icon-check", {
        opacity: 0,
        x: '-50%',
        y: '-50%',
    });

    tl.to(".anim2-row", {
        duration: 0.5,
        opacity: 1,
        x: 0,
        scale: 1,
        ease: Sine.easeInOut,
        stagger: 0.3
    });

    tl.add(animRow(2), '-=1.5');
    tl.add(animRow(5), '-=1.7');
    tl.add(animRow(1),'-=1.9');


    return tl;

}
