export function anim18() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    var tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    var tl3 = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    var tl4 = gsap.timeline({ repeat: -1, repeatDelay: 1 });



    tl3.to(".anim18-line", {
        duration: 0.5,
        x: "150%",
        immediateRender: false,
        transformOrigin: "50% 50%",
        ease: Power4.easeOut,
        stagger: .1
    });
    tl3.fromTo(".anim18-line", { transformOrigin: "50% 50%", opacity: 0, x: "-100%" }, {
        duration: 0.5,
        x: 0,
        opacity: 1,
        immediateRender: false,
        transformOrigin: "50% 50%",
        ease: Power3.easeOut,
        stagger: .1
    });

    tl2.to(".anim18-lines", {
        duration: 2,
        strokeDashoffset: -100,
        ease: "none"
    });
    tl.to(".anim18-btn", {
        duration: 1,
        scale: 1.1,
        transformOrigin: "30% 50%",
        ease: Power4.easeOut,
        stagger: .2,
    }, 0);
    tl.to(".anim18-btn", {
        duration: 1,
        scale: 1,
        transformOrigin: "30% 50%",
        ease: Power4.easeIn,
        stagger: .2,
    }, 1);



    return [tl, tl4, tl2, tl3 ];




}
