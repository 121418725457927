export function anim12() {


    var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    function animRow(nr) {

        var tl2 = gsap.timeline();
        var s = `.anim12-row${nr} .anim12-dolar`;
        tl2.set(s, {
            filter: 'saturate(0) brightness(1.8)',
        });

        tl2.to(s, {
            duration: 0.5,
            filter: 'saturate(1) brightness(1)',
            ease: Sine.easeInOut
        });
        return tl2;
    }


    // tl.set(".anim12-dolar", {
    //     filter: 'saturate(0) brightness(1.8)',
    // }); 
    // tl.to(".anim12-dolar", { 
    //     duration: 0.5, 
    //     filter: 'saturate(1) brightness(1)',
    //     ease: Sine.easeInOut,
    //     stagger: 0.3 });
    tl.add(animRow(3));
    tl.add(animRow(5));
    tl.add(animRow(6));
    tl.set(`.anim12-row4`, {
        backgroundImage: "linear-gradient(to right, #ADDBD2 0%, #fff 0%)"
    });
    tl.to(`.anim12-row4`, {
        duration: 1,
        // background:'#ADDBD2',
        backgroundImage: "linear-gradient(to right, #ADDBD2 80%, #fff 100%)"
    });
    tl.to(`.anim12-row4`, {
        duration: 0.3,
        background: '#fff'
    });
    tl.to(`.anim12-row4`, {
        duration: 0.3,
        background: '#ADDBD2'
    });
    tl.add(animRow(4));
    tl.to('.anim12-row4 .anim12-dolar', {
        scale: 3,
        ease: Power3.easeIn
    });
    tl.to('.anim12-row4 .anim12-dolar', {
        scale: 1,
        ease: Power3.easeOut
    });


    return tl;


}
