export function anim1() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    tl.set(".anim1-notif2, .anim1-notif", {
        scale: 0.7,
        x: "100%",
        opacity: 0,
    });
    var el = ".anim1-notif,.anim1-notif2";
    // tl.from(".anim1-notif,.anim1-notif2", { 
    //     duration: 1, 
    //     opacity: 0,
    //     y:"-10",
    //     scale: 0,
    //     ease: Elastic.easeOut,
    //     stagger: 1 });
    tl.to(el, {
        duration: 1,
        opacity: 1,
        x: 0,
        ease: Sine.easeOut,
        stagger: 3
    });
    tl.to(el, {
        duration: 1,
        y: "+=20",
        scale: 1,
        ease: Elastic.easeOut,
        stagger: 3
    }, "-=0.5");

    tl.to(el, {
        duration: 0.7,
        opacity: 0,
        y: "+=50%",
        scale: 0.7,
        ease: Sine.easeOut,
        stagger: 2
    }, "-=1.2");
   
    return tl;
}
