export function anim8() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 0, yoyo: true });


    tl.fromTo(".anim8-icon-bell", { rotate: -15, transformOrigin: "70% 12%" }, {
        duration: 0.3,
        rotate: 15,
        ease: Sine.easeInOut,
    });
    return tl;
}
